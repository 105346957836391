/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useReducer } from "react";
import firebase from "firebase/app";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import "firebase/storage";
import "firebase/firestore";
import "firebase/functions";
import { useParams } from "react-router-dom";
import {
  Container,
  Icon,
  Grid,
  Image,
  Header,
  Message,
} from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import theme from "./theme";
import "./App.css";

firebase.initializeApp({
  apiKey: "AIzaSyAYrys8GzbVtptCc-LgCKsdVNKO1vGlSgI",
  authDomain: "detecht-a7400.firebaseapp.com",
  databaseURL: "https://detecht-a7400.firebaseio.com",
  projectId: "detecht-a7400",
  storageBucket: "detecht-a7400.appspot.com",
  messagingSenderId: "287159139609",
  appId: "1:287159139609:web:27434cb74836f8447a9988",
  measurementId: "G-Y7ZK71KMNB",
});

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/:token">
          <Upload />
        </Route>
        <Route path="/">
          <Upload />
        </Route>
      </Switch>
    </Router>
  );
};

const Upload = () => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "startUpload":
        return {
          ...state,
          id: "",
          routeId: "",
          fetchingMapImage: false,
          uploading: true,
          finished: false,
          routeName: action.payload,
          error: "",
        };
      case "startConverting":
        return { ...state, uploading: false, converting: true };
      case "fetchMapImage":
        return { ...state, fetchingMapImage: true, converting: false };
      case "finishedConverting":
        return {
          ...state,
          fetchingMapImage: false,
          finished: true,
          mapImageURL: action.payload.mapImageURL,
          id: action.payload.id,
          importGPXDoc: {
            ...state.importGPXDoc,
            importedRoutes: action.payload.importedRoutes,
          },
        };
      case "setImportGPXDoc":
        const expired = moment(action.payload.expires.seconds * 1000).isBefore(
          moment()
        );
        return {
          ...state,
          loadingGPXDoc: false,
          importGPXDoc: action.payload,
          expired,
        };
      case "setRouteId":
        return { ...state, routeId: action.payload };
      case "setError":
        return { ...state, error: action.payload };
      case "invalidToken":
        return { ...state, invalidToken: true };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, null, () => ({
    id: "",
    routeId: "",
    routeName: "",
    importGPXDoc: {},
    expired: false,
    fetchingMapImage: false,
    mapImageURL: "",
    uploading: false,
    finished: false,
    loadingGPXDoc: true,
    error: "",
    invalidToken: false,
  }));

  console.log(state);

  const onDrop = useCallback(
    async (files) => {
      const routeName = files[0].name.slice(0, -4);
      const fileEnding = files[0].name.slice(-3).toLowerCase();

      if (
        !["gpx", "xml", "kurviger", "itn", "mps"].reduce(
          (acc, next) => fileEnding === next || acc,
          false
        )
      ) {
        dispatch({
          type: "setError",
          payload: [
            "Invalid file format",
            `It is currently only possible to upload GPX files. If you want to import a file in a different format, please drop an email to <a href="mailto:feedback@detecht.se">feedback@detecht.se</a> with reference id: <b>${token}</b>`,
          ],
        });

        return;
      }

      dispatch({
        type: "startUpload",
        payload: routeName,
      });

      const id = uuidv4();
      await firebase.storage().ref(`/v3_uploaded_gpx/${id}.gpx`).put(files[0]);
      dispatch({
        type: "startConverting",
      });

      try {
        const {
          data: { importedRoutes, routeId },
        } = await firebase
          .functions()
          .httpsCallable(`v3_convertGPX`)
          .call(null, {
            importGPXId: state.importGPXDoc.id,
            id,
            userId: state.importGPXDoc.userId,
            routeName,
          });

        dispatch({
          type: "setRouteId",
          payload: routeId,
        });

        dispatch({
          type: "fetchMapImage",
        });

        const mapImageURL = await firebase
          .storage()
          .ref(
            `/v3_map_images/${state.importGPXDoc.userId}/${routeId}_dark.jpg`
          )
          .getDownloadURL();

        dispatch({
          type: "finishedConverting",
          payload: { mapImageURL, id, importedRoutes },
        });
      } catch (error) {
        console.log(error);
        dispatch({
          type: "setError",
          payload: [
            "GPX decoding failed",
            `We were unable to decode the file, a ticket has been submitted and we will reach out to you via email as soon as it has been resolved.<br /><br />Your ticket id is: <b>${id}</b>`,
          ],
        });
      }
    },
    [state.importGPXDoc]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      firebase
        .firestore()
        .collection("v3_importGPX")
        .where("token", "==", token)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            dispatch({
              type: "invalidToken",
            });
          } else {
            dispatch({
              type: "setImportGPXDoc",
              payload: { ...snapshot.docs[0].data(), id: snapshot.docs[0].id },
            });
          }
        });
    } else {
    }
  }, [token]);

  const uploadLimitReached =
    state.importGPXDoc.importedRoutes &&
    state.importGPXDoc.importedRoutes.length >= 3 &&
    !state.importGPXDoc.isPremium;

  return (
    <Container
      style={{
        height: "100vh",
        backgroundColor: theme.systemBackgroundLight,
        boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.75)",
        padding: 10,
      }}
    >
      <Grid>
        <Grid.Row>
          <Grid.Column width={12} />
          <Grid.Column width={4}>
            <Image
              fluid
              src="./logo.png"
              style={{ marginTop: 24, marginBottom: 24, paddingRight: 24 }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            width={16}
            textAlign="center"
            style={{ marginTop: state.finished ? "10vh" : "24vh" }}
          >
            <Header
              style={{
                fontWeight: 100,
                fontSize: 38,
                marginBottom: 48,
                color: "#fff",
              }}
            >
              {!token || state.invalidToken
                ? `Invalid import token`
                : state.expired
                ? `Link expired, please generate a new one in the app`
                : state.loadingGPXDoc
                ? `Loading...`
                : state.finished
                ? `${state.routeName} successfully added to your routes!`
                : `Welcome ${state.importGPXDoc.userName}!`}
            </Header>
            {!state.loadingGPXDoc && !state.expired && !uploadLimitReached && (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {!state.error &&
                (state.finished ||
                  state.uploading ||
                  state.converting ||
                  state.fetchingMapImage) ? (
                  <Icon.Group
                    size="huge"
                    style={{ color: state.finished ? "#1d1" : "#fff" }}
                  >
                    {state.uploading || state.converting ? (
                      <Icon loading size="big" name="circle notch" />
                    ) : (
                      <Icon size="big" name="circle outline" />
                    )}
                    {state.finished ? (
                      <Icon name="check" />
                    ) : state.uploading ? (
                      <Icon name="hourglass one" />
                    ) : state.converting ? (
                      <Icon name="hourglass two" />
                    ) : (
                      <Icon name="hourglass three" />
                    )}
                  </Icon.Group>
                ) : isDragActive ? (
                  <Icon name="hand peace outline" size="massive" />
                ) : (
                  <Icon name="upload" size="massive" />
                )}
                <Header style={{ fontWeight: "100", color: "#fff" }}>
                  {state.finished ? (
                    <div></div>
                  ) : state.converting && !state.error ? (
                    <div>Converting GPX file to route ...</div>
                  ) : state.fetchingMapImage && !state.error ? (
                    <div>Almost there ...</div>
                  ) : state.uploading && !state.error ? (
                    <div>Uploading...</div>
                  ) : isDragActive ? (
                    <div>Drop the GPX-file here ...</div>
                  ) : (
                    <div>
                      Drag 'n' drop the GPX file here, or click to select files
                    </div>
                  )}
                </Header>
              </div>
            )}
          </Grid.Column>
          {state.finished && state.mapImageURL && !state.fetchingMapImage && (
            <Grid.Column width={16} textAlign="center">
              <Image
                src={state.mapImageURL}
                style={{ margin: "36px auto 0 auto" }}
              />
            </Grid.Column>
          )}
        </Grid.Row>
        {state.error && (
          <Grid.Row centered style={{ marginTop: 16 }}>
            <Grid.Column width={10}>
              <Message error>
                <Message.Header>{state.error[0]}</Message.Header>
                <p dangerouslySetInnerHTML={{ __html: state.error[1] }} />
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
        {uploadLimitReached && (
          <Grid.Row centered style={{ marginTop: 16 }}>
            <Grid.Column width={10}>
              <Message
                warning
                icon="warning"
                header="You have reached the limit of GPX imports included in your account."
                content="Sign up for Premium to upload unlimited number of routes!"
              />
            </Grid.Column>
          </Grid.Row>
        )}
        {(!token || state.invalidToken) && (
          <Grid.Row centered style={{ marginTop: 16 }}>
            <Grid.Column width={10}>
              <Message warning>
                <Message.Header>
                  Please generate a new token on your phones
                </Message.Header>
                <p>
                  Contact{" "}
                  <a href="mailto:feedback@detecht.se">feedback@detecht.se</a>{" "}
                  for support.
                </p>
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Container>
  );
};

export default App;
